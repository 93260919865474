import React from 'react';
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';
import { OverrideIcon } from '../types';

const icons = {
    IconDashboard,
    IconDeviceAnalytics
};

export interface DashboardMenuProps {
    id: string;
    type: string;
    children: {
        id: string;
        title: React.ReactNode | string;
        type: string;
        url: string;
        icon: OverrideIcon;
        breadcrumbs: boolean;
    }[];
}

const dashboard: DashboardMenuProps = {
    id: 'dashboard',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: '/dash-board',
            icon: icons.IconDashboard,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
